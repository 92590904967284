html,body {
  overflow-x: hidden;
  margin:0  !important;
}

/* File upload css */
.FileUpload > label > div > span {
  font-size: 16px !important;
  max-width: 100% !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.FileError > label > div > span {
  font-size: 16px !important;
  max-width: 100% !important;
  color: #f44336  !important;
}

/*.g-recaptcha {
  transform:scale(0.77);
  -webkit-transform:scale(0.77);
  transform-origin:center;
  -webkit-transform-origin:0 0;
}*/