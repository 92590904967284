@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



.montserrat-extra-light {
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 200 !important;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-snap-type: y mandatory;
}

.fade-in {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in-6 {
  animation: fadeInSlow 6s;
  -webkit-animation: fadeInSlow 6s;
  -moz-animation: fadeInSlow 6s;
  -o-animation: fadeInSlow 6s;
  -ms-animation: fadeInSlow 6s;
}

.fade-in-8 {
  animation: fadeInSlow 8s;
  -webkit-animation: fadeInSlow 8s;
  -moz-animation: fadeInSlow 8s;
  -o-animation: fadeInSlow 8s;
  -ms-animation: fadeInSlow 8s;
}

.fade-in-9 {
  animation: fadeInSlow 9s;
  -webkit-animation: fadeInSlow 9s;
  -moz-animation: fadeInSlow 9s;
  -o-animation: fadeInSlow 9s;
  -ms-animation: fadeInSlow 9s;
}

.fade-in-10 {
  animation: fadeInSlow 10s;
  -webkit-animation: fadeInSlow 10s;
  -moz-animation: fadeInSlow 10s;
  -o-animation: fadeInSlow 10s;
  -ms-animation: fadeInSlow 10s;
}

@keyframes fadeInSlow {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeInSlow {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeInSlow {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeInSlow {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeInSlow {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

